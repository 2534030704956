<template>
  <section class="hero">
    <div class="hero-container">
      <div class="column py-6">
        <div class="text">
          <h5 class="mb-2">{{ title }}</h5>
          <p v-html="presentation"></p>
          <div class="mt-4">
            <Button
              v-if="buttonShow"
              @action="redirectVote"
              color="white"
              background="green-900"
            >
              {{ buttonText }}
            </Button>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="media">
          <img v-if="videoImage" :src="videoImage" />
          <img v-if="!videoImage" src="../assets/images/hero/media.png" />
          <div v-if="videoUrl" class="play" @click="openModal">
            <i class="fas fa-play-circle"></i>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :visible="stateModal"
      :isMedia="true"
      size="lg"
      @emit-close-modal="closeModal"
    >
      <div class="aspect-w-16 aspect-h-9">
        <iframe
          :src="videoUrl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        >
        </iframe>
      </div>
    </Modal>
  </section>
</template>

<script>
import Vue from "vue";
import Button from "@/components/elements/Button.vue";
import Modal from "@/components/elements/Modal.vue";

export default Vue.extend({
  name: "Hero",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    presentation: {
      type: String,
      required: true,
      default: "",
    },
    buttonShow: {
      type: Boolean,
      require: false,
      default: false,
    },
    buttonText: {
      type: String,
      required: false,
      default: "",
    },
    buttonUrl: {
      type: String,
      required: false,
    },
    videoUrl: {
      type: String,
      required: false,
    },
    videoImage: {
      type: String,
      required: false,
    },
  },
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      stateModal: false,
    };
  },
  methods: {
    redirectVote() {
      window.open(this.buttonUrl, "_blank");
    },
    openModal() {
      this.stateModal = true;
    },
    closeModal() {
      this.stateModal = false;
    },
  },
});
</script>
