<template>
  <ul :class="type">
    <li v-for="(card, index) in cards" :key="index">
      <router-link
        :to="{ name: 'QuestionsToView', params: { slug: card.slug } }"
      >
        <div
          class="card-header"
          :style="{ backgroundImage: 'url(' + card.image + ')' }"
        ></div>
        <div class="card-body">
          <h2 class="title">{{ card.title }}</h2>
          <p class="description">
            {{ card.questions.length }}
            {{
              card.questions.length > 1
                ? "dúvidas encontradas"
                : "dúvida encontrada"
            }}
          </p>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Card",
  props: {
    type: {
      type: String,
      required: false,
      default: "card",
    },
    cards: {
      type: Array,
      required: true,
      default: () => [
        {
          image: {
            type: String,
            required: true,
          },
        },
        {
          title: {
            type: String,
            required: true,
          },
        },
        {
          slug: {
            type: String,
            required: true,
          },
        },
        {
          questions: {
            type: Array,
            required: true,
          },
        },
      ],
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/sass/card";
</style>
