<template>
  <main>
    <Hero
      :title="home.bemvindo"
      :presentation="home.mensageminicial"
      :videoImage="home.imagemvideo"
      :videoUrl="home.urlvideo"
      :buttonShow="home.temlinkvotar"
      :buttonText="home.msgSigaVoto"
      :buttonUrl="buttonUrl"
    ></Hero>
    <section v-if="cards.length > 0">
      <div class="section-container">
        <div class="section-title">
          <h4 class="text-lime-600">Precisando de ajuda?</h4>
        </div>
        <Card :cards="cards"></Card>
      </div>
    </section>
  </main>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Hero from "../components/Hero.vue";
import Card from "@/components/elements/Card.vue";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      home: {},
      cards: [],
      buttonUrl: "",
    };
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamHotsite"]),
  },
  components: {
    Hero,
    Card,
  },
  mounted() {
    this.home = this.getParamHotsite.home;
    this.buttonUrl = this.getParamHotsite.uri_votar;

    let filterCards = [];

    this.getParamHotsite.cards.forEach((card) => {
      if (card.home) {
        filterCards.push(card);
      }
    });

    this.cards = filterCards;
  },
});
</script>
